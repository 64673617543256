/* eslint-disable import/prefer-default-export */

export const preofferCacheStatusToText = (row) => {
  /* row must be InvoiceType */
  const status = row.preofferSelectable?.status;
  const { loanedTo } = row;
  const loandedMessage = loanedTo ? `Cedida a ${loanedTo}` : 'Cedida a otro factoring';
  return {
    EVALUATING: 'En evaluación',
    REJECTED: 'Rechazada en evaluación',
    LOANED: loandedMessage,
    HAS_CREDIT_NOTE: 'Con nota de crédito',
    HAS_PAYMENTS_EMITTED: 'Pagos emitidos',
    PROCESSING: 'Enviando',
    REJECTED_BY_SII: 'Rechazado por el SII',
    REJECTED_RECEIVER: 'Rechazado por receptor',
    REJECTED_FOR_CONTENT: 'Rechazado por falta de contenido',
    REJECTED_FOR_TOTAL: 'Rechazado por falta total de mercadería',
    REJECTED_FOR_PARTIAL: 'Rechazado por falta parcial de mercadería',
    VALID: 'Disponible',
    RECEPTION_ACKNOWLEDGED: 'Disponible,con acuse de recibo',
    PARTIAL_CLAIM: 'Reclamo parcial de mercadería',
    TOTAL_CLAIM: 'Reclamo total de mercadería',
    ALREADY_PAYED: 'Pagado al contado',
    INVALID_STATUS: 'Disponible',
    UNKNOWN_STATUS: 'Disponible',
    EXPIRED: 'Factura vencida',
    HAS_OFFER: 'Con oferta',
    HAS_OPERATION: 'Operada',
    IS_FOR_ORDERING: 'Para pago de OC',
  }[status];
};
