/* eslint-disable camelcase */
import _ from 'lodash';
import moment from 'moment';
import { cleanObjectFromUndefined } from './clean-object-from-undefined';

// This method unpacks an iterator and creates an object
function paramsToObject(entries) {
  const result = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

const getSerializedSearch = (searchObject) => {
  const copy = { ...searchObject };
  Object.keys(copy).forEach((key) => {
    // eslint-disable-next-line no-param-reassign
    copy[key] = JSON.stringify(copy[key]);
  });
  return `${new URLSearchParams(copy).toString()}`;
};

// This method seeks to push filter information to URL
const filterUrlMerger = (history, commonVarsCopy, filtersFromLocation) => {
  if (_.isEqual(commonVarsCopy, filtersFromLocation)) {
    return;
  }
  const search = getSerializedSearch(commonVarsCopy);
  history.replace({
    pathname: history.location.pathname,
    search,
  });
};

// This is to obtain the current search Object (pre serialization)
const getCurrentSearchObject = (history) => {
  const { search } = history.location;
  if (!search) {
    return {};
  }
  return paramsToObject(new URLSearchParams(search).entries());
};

// This method seeks to retrive filters from URL
const getFiltersFromHistory = (history) => {
  const
    { utm_campaign, apcid, ...filtersFromLocation } = getCurrentSearchObject(history);
  Object.keys(cleanObjectFromUndefined(filtersFromLocation)).forEach((key) => {
    filtersFromLocation[key] = JSON.parse(filtersFromLocation[key]);
  });
  return filtersFromLocation;
};

// This method seeks to retrive one filter from the URL
const getFilterByNameFromHistory = (history, name) => getFiltersFromHistory(history)[name];

// This method seeks to synchronize URL with Filters
const historyToFilters = (history, commonVars) => {
  const filtersFromLocation = getFiltersFromHistory(history);
  if (filtersFromLocation.first) {
    filterUrlMerger(history, { ...filtersFromLocation, ...commonVars }, filtersFromLocation);
  } else {
    filterUrlMerger(history, { ...commonVars, ...filtersFromLocation }, filtersFromLocation);
  }
};

// Pre-render use method to clean url
const getAndEraseFiltersFromHistory = (history, ...params) => {
  const updatedSearch = { ...getFiltersFromHistory(history) };
  const deletedValues = {};
  params.forEach((key) => {
    deletedValues[key] = updatedSearch[key];
    delete updatedSearch[key];
  });
  filterUrlMerger(history, updatedSearch, {});
  return deletedValues;
};

const getDefaultFiltersFromHistory = (history, initialOrderBy) => {
  const {
    first,
    offset,
    globalFilter,
    dateIssued_Gte,
    dateIssued_Lte,
    receiver_Rut,
    company_MasterEntity_Rut,
    dteType_Code_In,
    dfstateIn,
    allReceiverCompany,
    allIssuedCompany,
    documentType,
    orderBy,
    ...rest
  } = getFiltersFromHistory(history);
  return {
    defaultPageSize: first || 30,
    defaultPage: first && offset ? offset / first : 0,
    defaultDates: {
      dateIssued_Gte: dateIssued_Gte
        ? moment(dateIssued_Gte, 'YYYY-MM-DD')
        : moment().subtract(2, 'months'),
      dateIssued_Lte: dateIssued_Lte ? moment(dateIssued_Lte, 'YYYY-MM-DD') : moment(),
    },
    defaultGlobalFilter: globalFilter || '',
    defaultOrderBy: orderBy || initialOrderBy,
    defaultFilters: rest,
  };
};

export {
  getCurrentSearchObject,
  getDefaultFiltersFromHistory,
  getAndEraseFiltersFromHistory,
  getFilterByNameFromHistory,
  getSerializedSearch,
  historyToFilters,
  paramsToObject,
};
