export const moneyFormat = (amount, decimalCount = 0, decimal = ',', thousands = '.', symbol = '', showSymbol = false) => {
  let newDecimalCount = decimalCount;
  let newAmount = amount;
  newDecimalCount = Math.abs(newDecimalCount);
  newDecimalCount = Number.isNaN(newDecimalCount) ? 2 : newDecimalCount;

  const negativeSign = amount < 0 ? '-' : '';
  const i = parseInt(newAmount = Math.abs(Number(newAmount) || 0)
    .toFixed(newDecimalCount), 10)
    .toString();
  const j = (i.length > 3) ? i.length % 3 : 0;
  const symbolDisplay = showSymbol ? `${symbol} ` : '';
  return negativeSign + symbolDisplay + (j ? i.substr(0, j) + thousands : '') + i.substr(j)
    .replace(/(\d{3})(?=\d)/g, `$1${thousands}`)
    + (newDecimalCount ? decimal + Math.abs(newAmount - i).toFixed(newDecimalCount).slice(2) : '');
};

export const moneyInputFormat = (amount, decimalCount = 0, decimal = ',', thousands = '.') => {
  const newAmount = Math.abs(Number(amount) || 0);

  const negativeSign = amount < 0 ? '-' : '';
  const i = parseInt(newAmount.toFixed(decimalCount), 10).toString();
  const j = (i.length > 3) ? i.length % 3 : 0;

  let formattedAmount = negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`);

  if (decimalCount > 0) {
    const decimalPart = Math.abs(newAmount
       - parseInt(newAmount.toFixed(decimalCount), 10)).toFixed(decimalCount).slice(2);

    if (decimalPart !== '00') {
      formattedAmount += decimal + decimalPart.replace(/0+$/, '');
    } else {
      formattedAmount = formattedAmount.replace(decimal, '');
    }
  }

  return formattedAmount;
};

export const moneyFormats = {
  CLP: {
    decimalCount: 0,
    decimal: ',',
    thousands: '.',
    symbol: '$',
  },
  MXN: {
    decimalCount: 2,
    decimal: '.',
    thousands: ',',
    symbol: '$',
  },
  USD: {
    decimalCount: 2,
    decimal: '.',
    thousands: ',',
    symbol: 'USD',
  },
};
export const roundMoney = (money) => {
  const { amount, currency: { code } } = money;
  const { decimalCount } = moneyFormats[code];
  const newAmount = parseFloat(amount.toFixed(decimalCount));
  return { amount: newAmount, currency: { code } };
};

export const formatMoney = (moneyField, showSymbol = false) => {
  try {
    if (!moneyField.amount) {
      return moneyFormat(moneyField);
    }
    const { amount, currency: { code } } = moneyField;
    const { decimalCount, decimal, thousands, symbol } = moneyFormats[code];
    return moneyFormat(amount, decimalCount, decimal, thousands, symbol, showSymbol);
  } catch (e) {
    return '';
  }
};

export const formatMoneyWithSign = (moneyField) => `$${formatMoney(moneyField)}`;

export const addMoneyWithCurrency = (moneyField1, moneyField2) => {
  try {
    if (moneyField1 === 0) {
      return moneyField2;
    }
    if (moneyField2 === 0) {
      return moneyField1;
    }

    const { amount: amount1, currency: { code: code1 } } = moneyField1;
    const { amount: amount2, currency: { code: code2 } } = moneyField2;

    if (code1 !== code2) {
      throw new Error('Currencies are not the same');
    }

    const amount = amount1 + amount2;
    return { amount, currency: { code: code1 } };
  } catch (e) {
    return '';
  }
};

export const subtractMoneyWithCurrency = (moneyField1, moneyField2) => {
  try {
    if (moneyField1 === 0) {
      return moneyField2;
    }
    if (moneyField2 === 0) {
      return moneyField1;
    }

    const { amount: amount1, currency: { code: code1 } } = moneyField1;
    const { amount: amount2, currency: { code: code2 } } = moneyField2;

    if (code1 !== code2) {
      throw new Error('Currencies are not the same');
    }

    const amount = amount1 - amount2;
    return { amount, currency: { code: code1 } };
  } catch (e) {
    return '';
  }
};

export const multiplyMoneyWithCurrency = (moneyField, multiplier) => {
  try {
    const { amount, currency: { code } } = moneyField;
    const newAmount = (amount * multiplier);
    const moneyRounded = roundMoney({ amount: newAmount, currency: { code } });
    return moneyRounded;
  } catch (e) {
    return '';
  }
};

export const divideMoneyWithCurrency = (moneyField, divisor) => {
  try {
    const { amount, currency: { code } } = moneyField;
    const newAmount = (amount / divisor);
    const moneyRounded = roundMoney({ amount: newAmount, currency: { code } });
    return moneyRounded;
  } catch (e) {
    return '';
  }
};

export const divideTwoMoneyFields = (moneyField1, moneyField2) => {
  try {
    if (moneyField1 === 0) {
      return 0;
    }
    const { amount: amount1, currency: { code: code1 } } = moneyField1;
    const { amount: amount2, currency: { code: code2 } } = moneyField2;

    if (code1 !== code2) {
      throw new Error('Currencies are not the same');
    }

    const amount = amount1 / amount2;
    return amount;
  } catch (e) {
    return '';
  }
};

export const formatMillion = (x) => `${formatMoney((x / 1000000).toFixed(0))} MM`;

export const moneyToNumber = (money, decimalCount = 0, decimal = ',', thousands = '.') => {
  const stringMoney = money.toString();
  // round to decimalCount decimals
  const replaced = stringMoney
    .replace(new RegExp(`[${thousands}]`, 'g'), '') // Remove thousands separators
    .replace(new RegExp(`[${decimal}]`, 'g'), '.'); // Replace decimal separator with '.'
  return parseFloat(replaced).toFixed(decimalCount);
};
