/* eslint-disable */
export const DefaultDict = class {
  constructor(defaultInit) {
    return new Proxy({}, {
      get: (target, name) => (name in target
        ? target[name]
        : (target[name] = typeof defaultInit === 'function'
          ? new defaultInit().valueOf()
          : defaultInit)),
    });
  }
}

export const findProperty = (object, property) => {
  var value;
  Object.keys(object).some((key) => {
    if (key === property) {
      value = object[key];
      return true
    }
    if (object[key] && typeof object[key] === 'object') {
      value = findProperty(object[key], property);
      return value !== undefined;
    }
  });
  return value;
};
