export const orderingStatusToActiveStep = {
  EVALUATION: 0,
  OFFERED: 0,
  REJECTED: 0,
  PENDINGASSIGNMENTDOCUMENT: 1,
  PENDINGSIGNATURE: 1,
  PENDINGTRANSFER: 2,
  PENDINGINVOICES: 3,
  PROCESSINGINVOICES: 3,
  PENDINGCOLLECTION: 3,
  FACTORING: 3,
  FINISHED: 3,
};
export const orderingStatusToLabel = {
  CREATED: 0,
  SIMULATION: 0,
  EVALUATION: 1,
  REJECTED: 2,
  OFFERED: 3,
  PENDINGASSIGNMENTDOCUMENT: 3,
  PENDINGSIGNATURE: 3,
  PENDINGTRANSFER: 4,
  PENDINGCOLLECTION: 5,
  PENDINGINVOICES: 6,
  PROCESSINGINVOICES: 7,
  FACTORING: 8,
  FINISHED: 9,
};
export const orderingStatusToSpanish = {
  SIMULATION: 'Simulación',
  CREATED: 'Creada',
  EVALUATION: 'En evaluación',
  REJECTED: 'Rechazada',
  OFFERED: 'Aceptada',
  PENDINGASSIGNMENTDOCUMENT: 'Pendiente de mandato',
  PENDINGSIGNATURE: 'Pendiente de mandato',
  PENDINGTRANSFER: 'Pendiente de giro',
  PENDINGCOLLECTION: '💳 En recaudación',
  PENDINGINVOICES: '📒 Pendiente de factura',
  PROCESSINGINVOICES: '⏳ Procesando Cesión',
  FACTORING: '👩🏼‍💻 Facturas cedidas, no giradas aun',
  FINISHED: '🏆 Finalizada',
};

export const orderingStatusToStepLabels = (status) => ({
  0: ['Simulación', 'Firma mandato', 'Depósito', 'Factoring'],
  1: ['En evaluación', 'Firma mandato', 'Depósito', 'Factoring'],
  2: ['Rechazado', 'Firma mandato', 'Depósito', 'Factoring'],
  3: ['Aceptada', 'Firma mandato', 'Depósito', 'Factoring'],
  4: ['Aceptada', 'Firma mandato', 'Por depositar', 'Factoring'],
  5: ['Aceptada', 'Firma mandato', 'Depositada', 'En recaudación'],
  6: ['Aceptada', 'Firma mandato', 'Depositada', 'Espera factura'],
  7: ['Aceptada', 'Firma mandato', 'Depositada', 'Procesando cesión'],
  8: ['Aceptada', 'Firma mandato', 'Depositada', 'Factoring'],
  9: ['Aceptada', 'Firma mandato', 'Depositada', 'Recaudada'],
}[status]);

export const orderStatus = {
  Created: 'CREATED',
  Simulation: 'SIMULATION',
  Evaluation: 'EVALUATION',
  Offered: 'OFFERED',
  Rejected: 'REJECTED',
  PendingAssignmentDocument: 'PENDINGASSIGNMENTDOCUMENT',
  PendingSignature: 'PENDINGSIGNATURE',
  PendingTransfer: 'PENDINGTRANSFER',
  PendingInvoices: 'PENDINGINVOICES',
  PendingCollection: 'PENDINGCOLLECTION',
  ProcessingInvoices: 'PROCESSINGINVOICES',
  Factoring: 'FACTORING',
  Finished: 'FINISHED',
};

export const hasOrderingButtonDisable = (status) => {
  if (status === orderStatus.Offered
    || status === orderStatus.PendingInvoices
    || status === orderStatus.Simulation) {
    return false;
  }
  return true;
};
