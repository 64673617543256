import { toggleSiiForm } from '../apollo/reactive-variables';

const openSiiForm = (company) => {
  toggleSiiForm({ open: true, autoFillCompany: !!company });
};

const closeSiiForm = (company) => {
  toggleSiiForm({ open: false, autoFillCompany: !!company });
};

const openSiiFormWithoutAutoFill = () => {
  toggleSiiForm({ open: true, autoFillCompany: false });
};

export { openSiiForm, closeSiiForm, openSiiFormWithoutAutoFill };
