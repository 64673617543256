import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { SatLogo, SiiLogo, MexicoFlagIcon, ChileFlagIcon } from '../assets';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      Chile: {
        translation: {
          'National Tax Service': 'Servicio de Impuestos Internos',
          'National Tax Service credentials': 'Servicio de Impuestos Internos',
          'National Tax Service acronym': 'SII',
          'National Tax Service logo': SiiLogo,
          'National identifier': 'RUT',
          'Phone prefix': '56',
          'Phone Number Length': 9,
          'account number': 'Número de la cuenta',
          'National tax service credential provider': 'Sii',
          'offer action': 'Ceder y girar',
          'default currency': 'CLP',
          'Price diference': 'Diferencia de precio',
          retention: 'Monto retenido',
          dte: 'Tipo',
          flag: ChileFlagIcon,
          'landing-path': 'https://fingo.cl',
        },
      },
      Mexico: {
        translation: {
          'National Tax Service': 'Servicio de Administración Tributaria',
          'National Tax Service credentials': 'SAT (CIEC)',
          'National Tax Service acronym': 'SAT',
          'National Tax Service logo': SatLogo,
          'National identifier': 'RFC',
          'default currency': 'MXN',
          'Phone prefix': '52',
          'Phone Number Length': 10,
          'National tax service credential provider': 'Sat',
          'account number': 'Cuenta CLABE',
          'offer action': 'Ceder derechos de cobro y operar',
          'Price diference': 'Interés',
          retention: 'Monto retenido por aforo',
          dte: 'Efecto',
          flag: MexicoFlagIcon,
          'landing-path': 'https://www.fingo.com.mx/',
        },
      },
    },
    lng: 'Chile',
    fallbackLng: 'Chile',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
