/* eslint-disable max-len */
import moment from 'moment';

export const getTimeDiffText = (date, sinceDate = null) => {
  let today = sinceDate;
  if (sinceDate === null) {
    today = moment().startOf('day');
  }
  const diff = date.diff(today, 'days');
  if (diff < -1) return `Hace ${Math.abs(diff)} días`;
  if (diff === -1) return 'Ayer';
  if (diff === 0) return 'Hoy';
  if (diff === 1) return 'Mañana';
  return `En ${diff} días`;
};

export const getTimeDiffWithHoursMinutes = (date) => {
  if (!moment.isMoment(date)) return 'Sin fecha';
  const now = moment();
  const diff = date.diff(now);
  const duration = moment.duration(Math.abs(diff));
  const days = Math.floor(duration.asDays());

  const hours = duration.hours();
  const minutes = duration.minutes();

  if (diff < 0) {
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} horas y ${days} días`;
  }
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} horas y ${days} días`;
};

export const getTimeDiffOnlyHoursMinutes = (date) => {
  if (!moment.isMoment(date)) return 'Sin fecha';
  const now = moment();
  const diff = date.diff(now);
  const duration = moment.duration(Math.abs(diff));

  const days = Math.floor(duration.asDays());

  const hours = duration.hours() + days * 24;
  const minutes = duration.minutes();
  return `${hours.toString().padStart(2, '0')}H ${minutes
    .toString()
    .padStart(2, '0')}M`;
};

export const getTermTextBetweenDates = (date1, date2) => {
  const diff = date2.diff(date1, 'days');
  if (diff === 1) return '1 día';
  return `Financiamiento ${diff} días`;
};

export const formatDayMonthYear = (date) => {
  const dateMoment = moment(date);
  if (!dateMoment.isValid()) return null;
  return dateMoment.format('DD-MM-YYYY');
};

export const formatDayMonthYearSlash = (date) => date.format('DD/MM/YYYY');

export const formatYearMonthDay = (date) => date.format('YYYY-MM-DD');

export const formatMinWordDate = (date) => date.format('MMM DD').replace('.', '');

export const formatDateTime = (date) => date.format('YYYY-MM-DD HH:mm').replace('.', '');

export const formatGraphQlDate = (date) => date?.format('YYYY-MM-DD');

export const formatMonthYear = (date) => date?.format('MM/YYYY');

export const formatGraphQlDatetime = (date) => date?.toISOString();

export const formatTime = (date) => date.format('HH:mm');

export const formatDayWords = (date) => date.format('DD [de] MMMM');

export const formatDateWordsWithYear = (date) => date.format('DD [de] MMMM [de] YYYY');

export const dateCaster = () => {
  moment.locale('es');
  const myDate = moment();
  myDate.format('ll');
};
export const timeSinceGivenDate = (givenDate) => moment().to(moment(givenDate));

export const isWithinSpecifiedTime = (date, timeAmount, timeUnit) => moment(date).isAfter(moment().subtract(timeAmount, timeUnit));
